/* tslint:disable */
/* eslint-disable */
/**
 * App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../../../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../base';
// @ts-ignore
import { ChangePartyPasswordDto } from '../../../com/najd/models';
// @ts-ignore
import { CreatePartyDto } from '../../../com/najd/models';
// @ts-ignore
import { ListResultDtoOfPartyDto } from '../../../com/najd/models';
// @ts-ignore
import { PagedResultDtoOfPartyDto } from '../../../com/najd/models';
// @ts-ignore
import { PartyDto } from '../../../com/najd/models';
// @ts-ignore
import { PartyTokenDto } from '../../../com/najd/models';
// @ts-ignore
import { RegisterPartyAccountDto } from '../../../com/najd/models';
// @ts-ignore
import { RegisterPartyAccountOutputDto } from '../../../com/najd/models';
// @ts-ignore
import { RemoteServiceErrorResponse } from '../../../com/najd/models';
// @ts-ignore
import { SetndVerficationCodeDto } from '../../../com/najd/models';
// @ts-ignore
import { UpdatePartyDto } from '../../../com/najd/models';
/**
 * PartyApi - axios parameter creator
 * @export
 */
export const PartyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyActivePartyStatus: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partyActivePartyStatus', 'id', id)
            const localVarPath = `/api/md/parties/active-party-status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ChangePartyPasswordDto} [changePartyPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyChangePassword: async (id: string, changePartyPasswordDto?: ChangePartyPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partyChangePassword', 'id', id)
            const localVarPath = `/api/md/parties/change-password/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePartyPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [role] 
         * @param {string} [partyTypeCode] 
         * @param {string} [categoryId] 
         * @param {string} [itemCategoryId] 
         * @param {string} [categoryCode] 
         * @param {string} [locationId] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {string} [status] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyCount: async (filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (filterKey !== undefined) {
                localVarQueryParameter['FilterKey'] = filterKey;
            }

            if (role !== undefined) {
                localVarQueryParameter['Role'] = role;
            }

            if (partyTypeCode !== undefined) {
                localVarQueryParameter['PartyType_Code'] = partyTypeCode;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['Category_Id'] = categoryId;
            }

            if (itemCategoryId !== undefined) {
                localVarQueryParameter['ItemCategory_Id'] = itemCategoryId;
            }

            if (categoryCode !== undefined) {
                localVarQueryParameter['Category_Code'] = categoryCode;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['Location_Id'] = locationId;
            }

            if (cityId !== undefined) {
                localVarQueryParameter['City_Id'] = cityId;
            }

            if (subdivisionId !== undefined) {
                localVarQueryParameter['Subdivision_Id'] = subdivisionId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePartyDto} [createPartyDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyCreate: async (createPartyDto?: CreatePartyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPartyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyDeleteUser: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties/delete-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partyGet', 'id', id)
            const localVarPath = `/api/md/parties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [role] 
         * @param {string} [partyTypeCode] 
         * @param {string} [categoryId] 
         * @param {string} [itemCategoryId] 
         * @param {string} [categoryCode] 
         * @param {string} [locationId] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {string} [status] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyGetAssginableList: async (filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties/get-assginable-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (filterKey !== undefined) {
                localVarQueryParameter['FilterKey'] = filterKey;
            }

            if (role !== undefined) {
                localVarQueryParameter['Role'] = role;
            }

            if (partyTypeCode !== undefined) {
                localVarQueryParameter['PartyType_Code'] = partyTypeCode;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['Category_Id'] = categoryId;
            }

            if (itemCategoryId !== undefined) {
                localVarQueryParameter['ItemCategory_Id'] = itemCategoryId;
            }

            if (categoryCode !== undefined) {
                localVarQueryParameter['Category_Code'] = categoryCode;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['Location_Id'] = locationId;
            }

            if (cityId !== undefined) {
                localVarQueryParameter['City_Id'] = cityId;
            }

            if (subdivisionId !== undefined) {
                localVarQueryParameter['Subdivision_Id'] = subdivisionId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyGetByCode: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('partyGetByCode', 'code', code)
            const localVarPath = `/api/md/parties/by-code/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [role] 
         * @param {string} [partyTypeCode] 
         * @param {string} [categoryId] 
         * @param {string} [itemCategoryId] 
         * @param {string} [categoryCode] 
         * @param {string} [locationId] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {string} [status] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyGetList: async (filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (filterKey !== undefined) {
                localVarQueryParameter['FilterKey'] = filterKey;
            }

            if (role !== undefined) {
                localVarQueryParameter['Role'] = role;
            }

            if (partyTypeCode !== undefined) {
                localVarQueryParameter['PartyType_Code'] = partyTypeCode;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['Category_Id'] = categoryId;
            }

            if (itemCategoryId !== undefined) {
                localVarQueryParameter['ItemCategory_Id'] = itemCategoryId;
            }

            if (categoryCode !== undefined) {
                localVarQueryParameter['Category_Code'] = categoryCode;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['Location_Id'] = locationId;
            }

            if (cityId !== undefined) {
                localVarQueryParameter['City_Id'] = cityId;
            }

            if (subdivisionId !== undefined) {
                localVarQueryParameter['Subdivision_Id'] = subdivisionId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [role] 
         * @param {string} [partyTypeCode] 
         * @param {string} [categoryId] 
         * @param {string} [itemCategoryId] 
         * @param {string} [categoryCode] 
         * @param {string} [locationId] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {string} [status] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyGetListLazy: async (filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties/get-list-lazy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (filterKey !== undefined) {
                localVarQueryParameter['FilterKey'] = filterKey;
            }

            if (role !== undefined) {
                localVarQueryParameter['Role'] = role;
            }

            if (partyTypeCode !== undefined) {
                localVarQueryParameter['PartyType_Code'] = partyTypeCode;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['Category_Id'] = categoryId;
            }

            if (itemCategoryId !== undefined) {
                localVarQueryParameter['ItemCategory_Id'] = itemCategoryId;
            }

            if (categoryCode !== undefined) {
                localVarQueryParameter['Category_Code'] = categoryCode;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['Location_Id'] = locationId;
            }

            if (cityId !== undefined) {
                localVarQueryParameter['City_Id'] = cityId;
            }

            if (subdivisionId !== undefined) {
                localVarQueryParameter['Subdivision_Id'] = subdivisionId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skipCount !== undefined) {
                localVarQueryParameter['SkipCount'] = skipCount;
            }

            if (maxResultCount !== undefined) {
                localVarQueryParameter['MaxResultCount'] = maxResultCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyGetMy: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyInitalPartyUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties/inital-party-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userName] 
         * @param {string} [partyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyIsUserNameValidToUse: async (userName?: string, partyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties/is-username-valid-to_use`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (partyId !== undefined) {
                localVarQueryParameter['party_Id'] = partyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [identifier] 
         * @param {string} [partyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyIsValidIdentifierToUse: async (identifier?: string, partyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties/is-valid-identifier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (identifier !== undefined) {
                localVarQueryParameter['Identifier'] = identifier;
            }

            if (partyId !== undefined) {
                localVarQueryParameter['Party_Id'] = partyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {string} [partyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyIsValidPhoneToUse: async (phoneNumber?: string, partyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties/is-valid-phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['PhoneNumber'] = phoneNumber;
            }

            if (partyId !== undefined) {
                localVarQueryParameter['Party_Id'] = partyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterPartyAccountDto} [registerPartyAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyRegisterAccount: async (registerPartyAccountDto?: RegisterPartyAccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties/register-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerPartyAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PartyTokenDto} [partyTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partySetPartyToken: async (partyTokenDto?: PartyTokenDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties/set-party-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partyTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [userName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partySetUserAcccount: async (id?: string, userName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties/set-user-acccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetndVerficationCodeDto} [setndVerficationCodeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partySetndVerficationCode: async (setndVerficationCodeDto?: SetndVerficationCodeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties/send-verfication-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setndVerficationCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {UpdatePartyDto} [updatePartyDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyUpdate: async (id?: string, updatePartyDto?: UpdatePartyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/md/parties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePartyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartyApi - functional programming interface
 * @export
 */
export const PartyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyActivePartyStatus(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyActivePartyStatus(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ChangePartyPasswordDto} [changePartyPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyChangePassword(id: string, changePartyPasswordDto?: ChangePartyPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyChangePassword(id, changePartyPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [role] 
         * @param {string} [partyTypeCode] 
         * @param {string} [categoryId] 
         * @param {string} [itemCategoryId] 
         * @param {string} [categoryCode] 
         * @param {string} [locationId] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {string} [status] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyCount(filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyCount(filterKey, role, partyTypeCode, categoryId, itemCategoryId, categoryCode, locationId, cityId, subdivisionId, status, sorting, skipCount, maxResultCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreatePartyDto} [createPartyDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyCreate(createPartyDto?: CreatePartyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyCreate(createPartyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyDeleteUser(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyDeleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [role] 
         * @param {string} [partyTypeCode] 
         * @param {string} [categoryId] 
         * @param {string} [itemCategoryId] 
         * @param {string} [categoryCode] 
         * @param {string} [locationId] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {string} [status] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyGetAssginableList(filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResultDtoOfPartyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyGetAssginableList(filterKey, role, partyTypeCode, categoryId, itemCategoryId, categoryCode, locationId, cityId, subdivisionId, status, sorting, skipCount, maxResultCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyGetByCode(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyGetByCode(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [role] 
         * @param {string} [partyTypeCode] 
         * @param {string} [categoryId] 
         * @param {string} [itemCategoryId] 
         * @param {string} [categoryCode] 
         * @param {string} [locationId] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {string} [status] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyGetList(filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResultDtoOfPartyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyGetList(filterKey, role, partyTypeCode, categoryId, itemCategoryId, categoryCode, locationId, cityId, subdivisionId, status, sorting, skipCount, maxResultCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [role] 
         * @param {string} [partyTypeCode] 
         * @param {string} [categoryId] 
         * @param {string} [itemCategoryId] 
         * @param {string} [categoryCode] 
         * @param {string} [locationId] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {string} [status] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyGetListLazy(filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultDtoOfPartyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyGetListLazy(filterKey, role, partyTypeCode, categoryId, itemCategoryId, categoryCode, locationId, cityId, subdivisionId, status, sorting, skipCount, maxResultCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyGetMy(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyGetMy(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyInitalPartyUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyInitalPartyUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userName] 
         * @param {string} [partyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyIsUserNameValidToUse(userName?: string, partyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyIsUserNameValidToUse(userName, partyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [identifier] 
         * @param {string} [partyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyIsValidIdentifierToUse(identifier?: string, partyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyIsValidIdentifierToUse(identifier, partyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {string} [partyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyIsValidPhoneToUse(phoneNumber?: string, partyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyIsValidPhoneToUse(phoneNumber, partyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterPartyAccountDto} [registerPartyAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyRegisterAccount(registerPartyAccountDto?: RegisterPartyAccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterPartyAccountOutputDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyRegisterAccount(registerPartyAccountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PartyTokenDto} [partyTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partySetPartyToken(partyTokenDto?: PartyTokenDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partySetPartyToken(partyTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [userName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partySetUserAcccount(id?: string, userName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partySetUserAcccount(id, userName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetndVerficationCodeDto} [setndVerficationCodeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partySetndVerficationCode(setndVerficationCodeDto?: SetndVerficationCodeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partySetndVerficationCode(setndVerficationCodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {UpdatePartyDto} [updatePartyDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partyUpdate(id?: string, updatePartyDto?: UpdatePartyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partyUpdate(id, updatePartyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartyApi - factory interface
 * @export
 */
export const PartyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyActivePartyStatus(id: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.partyActivePartyStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ChangePartyPasswordDto} [changePartyPasswordDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyChangePassword(id: string, changePartyPasswordDto?: ChangePartyPasswordDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.partyChangePassword(id, changePartyPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [role] 
         * @param {string} [partyTypeCode] 
         * @param {string} [categoryId] 
         * @param {string} [itemCategoryId] 
         * @param {string} [categoryCode] 
         * @param {string} [locationId] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {string} [status] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyCount(filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): AxiosPromise<number> {
            return localVarFp.partyCount(filterKey, role, partyTypeCode, categoryId, itemCategoryId, categoryCode, locationId, cityId, subdivisionId, status, sorting, skipCount, maxResultCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreatePartyDto} [createPartyDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyCreate(createPartyDto?: CreatePartyDto, options?: any): AxiosPromise<PartyDto> {
            return localVarFp.partyCreate(createPartyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyDelete(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.partyDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyDeleteUser(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.partyDeleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyGet(id: string, options?: any): AxiosPromise<PartyDto> {
            return localVarFp.partyGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [role] 
         * @param {string} [partyTypeCode] 
         * @param {string} [categoryId] 
         * @param {string} [itemCategoryId] 
         * @param {string} [categoryCode] 
         * @param {string} [locationId] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {string} [status] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyGetAssginableList(filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): AxiosPromise<ListResultDtoOfPartyDto> {
            return localVarFp.partyGetAssginableList(filterKey, role, partyTypeCode, categoryId, itemCategoryId, categoryCode, locationId, cityId, subdivisionId, status, sorting, skipCount, maxResultCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyGetByCode(code: string, options?: any): AxiosPromise<PartyDto> {
            return localVarFp.partyGetByCode(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [role] 
         * @param {string} [partyTypeCode] 
         * @param {string} [categoryId] 
         * @param {string} [itemCategoryId] 
         * @param {string} [categoryCode] 
         * @param {string} [locationId] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {string} [status] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyGetList(filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): AxiosPromise<ListResultDtoOfPartyDto> {
            return localVarFp.partyGetList(filterKey, role, partyTypeCode, categoryId, itemCategoryId, categoryCode, locationId, cityId, subdivisionId, status, sorting, skipCount, maxResultCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filterKey] 
         * @param {string} [role] 
         * @param {string} [partyTypeCode] 
         * @param {string} [categoryId] 
         * @param {string} [itemCategoryId] 
         * @param {string} [categoryCode] 
         * @param {string} [locationId] 
         * @param {string} [cityId] 
         * @param {string} [subdivisionId] 
         * @param {string} [status] 
         * @param {string} [sorting] 
         * @param {number} [skipCount] 
         * @param {number} [maxResultCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyGetListLazy(filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: any): AxiosPromise<PagedResultDtoOfPartyDto> {
            return localVarFp.partyGetListLazy(filterKey, role, partyTypeCode, categoryId, itemCategoryId, categoryCode, locationId, cityId, subdivisionId, status, sorting, skipCount, maxResultCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyGetMy(options?: any): AxiosPromise<PartyDto> {
            return localVarFp.partyGetMy(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyInitalPartyUsers(options?: any): AxiosPromise<void> {
            return localVarFp.partyInitalPartyUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userName] 
         * @param {string} [partyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyIsUserNameValidToUse(userName?: string, partyId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.partyIsUserNameValidToUse(userName, partyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [identifier] 
         * @param {string} [partyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyIsValidIdentifierToUse(identifier?: string, partyId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.partyIsValidIdentifierToUse(identifier, partyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {string} [partyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyIsValidPhoneToUse(phoneNumber?: string, partyId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.partyIsValidPhoneToUse(phoneNumber, partyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterPartyAccountDto} [registerPartyAccountDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyRegisterAccount(registerPartyAccountDto?: RegisterPartyAccountDto, options?: any): AxiosPromise<RegisterPartyAccountOutputDto> {
            return localVarFp.partyRegisterAccount(registerPartyAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PartyTokenDto} [partyTokenDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partySetPartyToken(partyTokenDto?: PartyTokenDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.partySetPartyToken(partyTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [userName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partySetUserAcccount(id?: string, userName?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.partySetUserAcccount(id, userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetndVerficationCodeDto} [setndVerficationCodeDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partySetndVerficationCode(setndVerficationCodeDto?: SetndVerficationCodeDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.partySetndVerficationCode(setndVerficationCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {UpdatePartyDto} [updatePartyDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyUpdate(id?: string, updatePartyDto?: UpdatePartyDto, options?: any): AxiosPromise<PartyDto> {
            return localVarFp.partyUpdate(id, updatePartyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartyApi - object-oriented interface
 * @export
 * @class PartyApi
 * @extends {BaseAPI}
 */
export class PartyApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyActivePartyStatus(id: string, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyActivePartyStatus(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ChangePartyPasswordDto} [changePartyPasswordDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyChangePassword(id: string, changePartyPasswordDto?: ChangePartyPasswordDto, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyChangePassword(id, changePartyPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filterKey] 
     * @param {string} [role] 
     * @param {string} [partyTypeCode] 
     * @param {string} [categoryId] 
     * @param {string} [itemCategoryId] 
     * @param {string} [categoryCode] 
     * @param {string} [locationId] 
     * @param {string} [cityId] 
     * @param {string} [subdivisionId] 
     * @param {string} [status] 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyCount(filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyCount(filterKey, role, partyTypeCode, categoryId, itemCategoryId, categoryCode, locationId, cityId, subdivisionId, status, sorting, skipCount, maxResultCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreatePartyDto} [createPartyDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyCreate(createPartyDto?: CreatePartyDto, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyCreate(createPartyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyDelete(id?: string, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyDeleteUser(id?: string, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyDeleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyGet(id: string, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filterKey] 
     * @param {string} [role] 
     * @param {string} [partyTypeCode] 
     * @param {string} [categoryId] 
     * @param {string} [itemCategoryId] 
     * @param {string} [categoryCode] 
     * @param {string} [locationId] 
     * @param {string} [cityId] 
     * @param {string} [subdivisionId] 
     * @param {string} [status] 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyGetAssginableList(filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyGetAssginableList(filterKey, role, partyTypeCode, categoryId, itemCategoryId, categoryCode, locationId, cityId, subdivisionId, status, sorting, skipCount, maxResultCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyGetByCode(code: string, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyGetByCode(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filterKey] 
     * @param {string} [role] 
     * @param {string} [partyTypeCode] 
     * @param {string} [categoryId] 
     * @param {string} [itemCategoryId] 
     * @param {string} [categoryCode] 
     * @param {string} [locationId] 
     * @param {string} [cityId] 
     * @param {string} [subdivisionId] 
     * @param {string} [status] 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyGetList(filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyGetList(filterKey, role, partyTypeCode, categoryId, itemCategoryId, categoryCode, locationId, cityId, subdivisionId, status, sorting, skipCount, maxResultCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filterKey] 
     * @param {string} [role] 
     * @param {string} [partyTypeCode] 
     * @param {string} [categoryId] 
     * @param {string} [itemCategoryId] 
     * @param {string} [categoryCode] 
     * @param {string} [locationId] 
     * @param {string} [cityId] 
     * @param {string} [subdivisionId] 
     * @param {string} [status] 
     * @param {string} [sorting] 
     * @param {number} [skipCount] 
     * @param {number} [maxResultCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyGetListLazy(filterKey?: string, role?: string, partyTypeCode?: string, categoryId?: string, itemCategoryId?: string, categoryCode?: string, locationId?: string, cityId?: string, subdivisionId?: string, status?: string, sorting?: string, skipCount?: number, maxResultCount?: number, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyGetListLazy(filterKey, role, partyTypeCode, categoryId, itemCategoryId, categoryCode, locationId, cityId, subdivisionId, status, sorting, skipCount, maxResultCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyGetMy(options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyGetMy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyInitalPartyUsers(options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyInitalPartyUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userName] 
     * @param {string} [partyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyIsUserNameValidToUse(userName?: string, partyId?: string, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyIsUserNameValidToUse(userName, partyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [identifier] 
     * @param {string} [partyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyIsValidIdentifierToUse(identifier?: string, partyId?: string, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyIsValidIdentifierToUse(identifier, partyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [phoneNumber] 
     * @param {string} [partyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyIsValidPhoneToUse(phoneNumber?: string, partyId?: string, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyIsValidPhoneToUse(phoneNumber, partyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterPartyAccountDto} [registerPartyAccountDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyRegisterAccount(registerPartyAccountDto?: RegisterPartyAccountDto, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyRegisterAccount(registerPartyAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PartyTokenDto} [partyTokenDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partySetPartyToken(partyTokenDto?: PartyTokenDto, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partySetPartyToken(partyTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [userName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partySetUserAcccount(id?: string, userName?: string, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partySetUserAcccount(id, userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetndVerficationCodeDto} [setndVerficationCodeDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partySetndVerficationCode(setndVerficationCodeDto?: SetndVerficationCodeDto, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partySetndVerficationCode(setndVerficationCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {UpdatePartyDto} [updatePartyDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    public partyUpdate(id?: string, updatePartyDto?: UpdatePartyDto, options?: AxiosRequestConfig) {
        return PartyApiFp(this.configuration).partyUpdate(id, updatePartyDto, options).then((request) => request(this.axios, this.basePath));
    }
}
